label {
  &.error {
    color: $error;
    font-size: 12px;
  }
}

.log {
  display: flex;
  justify-content: center;
  align-items: center;

  .card-wrapper {
    text-align: center;
    // position: absolute;
    // top: 40%;
    // left: 50%;
    // -ms-transform: translate(-50%, -50%);
    // transform: translate(-50%, -50%);
    min-width: 500px;

    .ant-card-body {
      padding: 74px;
    }

    @include media('<md') {
      min-width: 320px;

      .ant-card-body {
        padding: 34px;

        .login-logo {
          max-width: 150px !important;
        }

        // .ant-card-meta-title {
        //   font-size: 16px;
        // }
      }
    }

    .ant-card-head-title {
      font-size: 18px;
      font-weight: 700;
      // color: #ffffff;
      color: #2c2638;
      font-weight: 100;
    }
  }
}

.dashboard {
  height: 100%;

  .card-wrapper {
    display: flex;
    height: 100%;

    .ant-card {
      width: 50%;

      @include media('<lg') {
        width: 100%;
      }

      .ant-card-body {
        padding: 10px 15px;
      }
    }
  }
}

.ant-form-item-explain div {
  float: left;
}

.ant-form-item.upload-wrapper.right {
  .ant-form-item-control {
    flex-direction: row;
    justify-content: flex-start;
  }

  .ant-upload-list-picture-card-container,
  .ant-upload.ant-upload-select-picture-card {
    margin: 0;
  }
}

.modal {
  .ant-form-item.upload-wrapper.right {
    .ant-form-item-control {
      justify-content: center;
    }
  }
}

.ant-card {
  border-radius: $table-border-radius;
  box-shadow: $table-box-shadow;

  &.login-header {
    .ant-card-head {
      color: #2c2638;
      background-color: #ffffff;
    }
  }
}

.ant-card-head {
  // background-color: #4f66a0;
  // color: #ffffff;
  // color: #2c2638;
  color: #ffffff;
  // background-color: #ffffff;
  background: $primary-color;
  text-transform: capitalize;
  border-radius: $table-border-radius-top;

  .ant-card-head-title {
    //added
    text-transform: initial;
  }
}

.ant-picker-large {
  padding: 5px 11px 6px;
}

.panel-heading .panel-title span {
  background-color: $primary-color !important;
}

.log {
  .card-wrapper {
    .ant-card-head-title {
      text-align: center;
    }
  }
}

.ant-card {
  &.profile-title {
    .ant-card-head {
      background-color: #848587;
      color: #ffffff;
    }
  }
}

@media only screen and (max-width: 400px) {
  .ant-card-head {
    padding: 12px;
    font-size: 14px;
  }

  .ant-card-body {
    padding: 12px;
    font-size: 12px;
  }
}

.ant-form-item-label {
  min-width: 100px;
}

input,
select {
  font-size: 100% !important;
}

.eventField {
  color: #000000 !important;
}

.avatar .ant-form-item-control {
  align-items: center;
}

.link-forgot-password {
  color: black !important;
}

.link-forgot-password:hover {
  color: $primary-color !important;
  text-decoration: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* PBB Report Form */

.pbb-form-panel {
  width: 100%;
  display: flex;
  gap: 2rem;
}

.pbb-form-body {
  @media screen and (min-width: 1240px) {
    width: 80%;
    margin-left: auto;
  }
}

.pbb-form-navigation {
  display: none;

  @media screen and (min-width: 1240px) {
    position: fixed;
    display: block;
    top: unset;
    justify-self: flex-start;
    text-align: left;

    &__title {
      font-weight: 700;
      margin-bottom: 1rem;
      display: none;
    }

    &__item {
      font-size: 0.925rem;

      &--child {
        font-size: 0.925rem;
        padding: 0 0.625rem;
      }
    }
  }
}

.pbb-select-navigation {
  z-index: 999;
  position: fixed;
  top: 0;
  left: unset;
  display: flex;
  justify-content: center;
  width: calc(100% - 245px);

  @media screen and (max-width: 992px) {
    left: 0;
    width: 100%;
  }

  @media screen and (min-width: 1240px) {
    display: none;
  }

  .ant-select {
    width: 200px;
    border-radius: 0px 0px 8px 8px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .ant-select-selector {
    border-radius: 0px 0px 8px 8px !important;
  }

  .ant-select-selection-item {
    pointer-events: none;
  }
}

.ant-select-item-option-content a {
  display: block;
}

.pbb-form-drawer {
  .ant-drawer-body {
    padding: 10px;
  }

  .ant-drawer-content-wrapper {
    width: 95% !important;

    @media screen and (min-width: 768px) {
      width: 75% !important;
    }

    @media screen and (min-width: 996px) {
      width: 50% !important;
    }

    @media screen and (min-width: 1200px) {
      max-width: 600px !important;
    }
  }

  .ant-form-item {
    margin-bottom: 16px;
  }

  .ant-card-head-title {
    white-space: normal;
  }

  .ant-row-middle {
    padding-bottom: 1rem;

    * {
      margin: 0;
    }

    .ant-col {
      font-size: 12px;
    }
  }

  .ant-divider-horizontal {
    margin-top: 0;
  }

  .ant-form > div > h3 {
    margin-top: 2rem;
    margin-bottom: 0;
    font-size: 1.5rem;
    font-weight: 700;
  }
}

.pbbReportDivider {
  border-top: 0.5px solid #374b58;
}

.pbbCheckbox {
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 4px 11px;

  & .ant-checkbox + span {
    padding-left: unset;
    flex: 1;
  }
}
.compartment-heights-levels {
  .pbbCheckbox {
    padding: 4px 6px;
    .ant-checkbox + span {
      font-size: 0.75rem;
    }
  }
}

.pbbText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #e30613;
}

.pbbPenalty {
  display: flex;
  justify-content: space-between;
  background-color: #f1f1f1;
  align-items: center;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 5px;
}

.penaltyText {
  width: 100%;
  text-align: end;
  font-size: 12px;
  margin-bottom: 24px;
}

.pbbCard {
  box-shadow: unset;
  margin-bottom: 24px;

  & .ant-card-head {
    background-color: #f1f1f1;
    min-height: 40px;
    color: #374b58;
    align-items: center;
    padding: 0 15px;

    & .ant-card-head-title {
      padding: 10px 0;
    }
  }

  & .ant-card-body {
    padding: 5px;
    overflow-wrap: anywhere;

    & .ant-form-item {
      margin-bottom: 0;

      & .ant-radio-group {
        width: 100%;
        padding: 0 10px;

        & .ant-space {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}

.pbb-section-title {
  flex-grow: 1;
  white-space: nowrap;
  margin-right: 8px;
  font-size: 15px;
  font-weight: 700;
}

.pbbAssembly {
  & .ant-card-head {
    text-transform: none;

    & .ant-card-head-title {
      min-width: 35%;
    }
  }
}
.pbbAssembly-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
}

.pbbRadioOption {
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-between;

  // border-top: 0.5px solid #374b58;
  & span:last-of-type {
    flex: 1;
  }
}

.pbbCheckboxSecond {
  padding-left: 15px;
  background: #f1f1f1;
  border: 0.4px solid #374b58;
  border-radius: 4px;
}

.pbbRadioOptionSecond {
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 10px 11px;
  background-color: #f1f1f1;

  & span:last-of-type {
    flex: 1;
  }
}

.uploadSketchButton {
  background: #fce6e7;
  color: #e30613;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.embedImageButton {
  background: #fce6e7;
  color: #e30613;
  border-radius: 8px;
}

.pbbForm {
  & .ant-upload {
    width: 100%;
  }

  h3 {
    font-weight: 700;
    font-size: 1.5rem;
  }

  .ant-space {
    flex-wrap: wrap;
  }

  // .ant-form-item input::placeholder,
  // .ant-form-item input::-webkit-input-placeholder,
  // .ant-input-suffix,
  // .ant-input-prefix
  // {
  //   color: #374b58;
  // }
}

.pbb-product {
  margin-bottom: 1rem;
  padding: 0.5rem 0;
}

.suffixCheckbox,
.prefixCheckbox {
  margin-bottom: 0 !important;

  & .ant-form-item-control-input {
    min-height: unset;
  }
}

.pbbUploadContainer {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 5px;
}

.pbbUploadField {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px dashed #bbbbbb;
  border-radius: 8px;
  padding: 15px;

  .anticon {
    font-size: 1.5rem;
  }
}

.embedImageTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  margin: 10px 0px !important;
}

.filesText {
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  color: #bbbbbb;
  margin-bottom: 15px !important;
}

.maxSizeText {
  font-weight: 600;
  font-size: 11px;
  line-height: 12px;
  text-align: center;
  color: #747474;
  margin-top: 10px !important;
  margin-bottom: 15px !important;
}

.heightsTitle {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
}

.heightInput {
  margin-bottom: 16px;
  padding: 0px 11px;
  background-color: #fff;
  cursor: default;

  & input {
    text-align: center;
    cursor: default !important;
    color: rgba(0, 0, 0, 0.85) !important;
  }

  & .ant-input-suffix {
    cursor: pointer !important;

    & .ant-checkbox input {
      cursor: pointer !important;
    }
  }

  & .ant-input-prefix {
    cursor: pointer !important;

    & .ant-checkbox input {
      cursor: pointer !important;
    }
  }
}

.sectionTitle {
  display: flex;
  width: 100%;
  align-items: center;
}

.pbbCheckboxThree {
  width: 100%;
  border: 1px solid #d9d9d9;
  padding: 4px 11px;

  & .ant-checkbox + span {
    flex: 1;
  }
}

.ant-card-head-title {
  white-space: normal;
}

.pbb-form-body {
  max-width: 100%;
}

.generalInformationField {
  align-self: flex-end;
}

.footer {
  background-color: #cacaca;
  padding: 20px 0px;
  font-size: 1em !important;
}
.special-three {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.aisle-flooring-levels .ant-form-item-label {
  text-align: center;
  font-weight: 600;
}
