.h-100 {
    height:100%;
}

.mh-100 {
    min-height: 100%;
}

.pt-0 {
    padding-top: 0 !important;
}

.m-block-10 {
  margin-block: 10px!important;
}

.inline-flex {
    display: flex;
    justify-content: space-between;
}

.inline-flex-around {
  display: flex;
  justify-content: space-around;
}

.inline-flex-center {
  display: flex;
  justify-content: center; 
  align-items: center;
}

.inline-flex-vertical {
  display: flex;
  flex-direction: column;
}

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
}
