html {
  font-size: 100%;
}

body {
  color: $txtColor !important;
  // font-family:$font-stack !important;
  @include font-size(16);
  line-height: 1.2;
  text-size-adjust: 100%;
}

h2 {
  font-size: 16px;
}

hr.big {
  margin: 2rem 0;
  border: 1px solid #dbe2ea;
}

dl {
  dt {
    font-weight: bold;
    margin-right: 0.375em;
    display: inline-block;
    margin-top: 0.625em;

    &:first-child {
      margin-top: 0;
    }
  }

  dd {
    display: inline;
    &:after {
      content: '\A';
      white-space: pre;
    }
  }
}
