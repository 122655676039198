.ant-statistic-content-prefix {
    .anticon {
        vertical-align: baseline;
        position: relative;
        top: -0.2ex;
    }
}

// .ant-checkbox-checked {
//     .ant-checkbox-inner {
//         background-color: #259dab !important;
//         border-color: #259dab !important;
//     }
// }


.ant-checkbox-checked {
    .ant-checkbox-inner {
        background-color: $primary-color !important;
        border-color: $primary-color !important;
    }
}

.ant-card-meta-title {
    @include heading($heading-font-size, $k100);
    text-align: left;
    margin-bottom: 30px;
}

.bold-label {
    font-weight: $bold-font-weight;
    font-size: $regular-font-size;
    line-height: $regular-line-height;
    text-transform: capitalize;
}

.anticon.ant-notification-notice-icon-success svg {
    fill: #52c41a !important;
}

.anticon.ant-notification-notice-icon-error svg {
    fill: #ff4d4f !important;
}

.ant-layout{
    background: $background-color;
}

.ant-layout-sider-children {
    overflow-y: scroll;
    scrollbar-width: none;
}

.ant-layout-sider-children::-webkit-scrollbar {
    display: none;
  }