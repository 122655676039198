.ant-table-wrapper {
  padding-bottom: 20px;
  background: #fff;
}

.ant-table {
  font-size: 1em;
}

.ant-table table {
  width: 98%;
  margin: 0 auto;
  border: 1px solid #e3e3e3 !important;
  border-radius: 5px;

  tbody {
    > tr {
      background-color: $wht;

      &:nth-child(2n + 1) {
        background-color: $wht;
      }
    }
  }
}

.ant-table-column-title:first-letter {
  text-transform: capitalize !important;
}

.ant-table-filter-column {
  justify-content: flex-start;
}
.ant-table-filter-column-title:first-letter {
  text-transform: capitalize !important;
}

.ant-table-thead > tr > th {
  background-color: $wht;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, sans-serif;

  &.ant-table-column-has-actions {
    &.ant-table-column-has-sorters:hover {
      background-clip: padding-box;
      background-color: darken($c-primary, 3);
    }

    &.ant-table-column-has-filters {
      &:hover {
        .ant-table-filter-icon:hover,
        .anticon-filter:hover {
          background-color: darken($c-primary, 3);
          color: #fff;
        }
      }

      .anticon-filter {
        &.ant-table-filter-open {
          background-color: darken($c-primary, 3);
          color: #fff;
        }
      }

      .ant-table-filter-icon.ant-table-filter-open {
        background-color: darken($c-primary, 3);
        color: #fff;
      }
    }
  }

  &.ant-table-column-sort {
    background-color: darken($c-primary, 3);
  }

  .ant-table-column-sorter {
    color: #fff;

    &-down.on,
    &-up.on {
      color: yellow;
    }
  }

  .ant-table-filter-selected.anticon-filter {
    color: yellow;
  }
}

.ant-table-bordered {
  .ant-table-thead > tr > th {
    border-right-color: lighten($c-gray, 5);
    border-bottom: 1px solid $c-gray;
    color: $table-title-color;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    background: #ebebeb;
  }
  .ant-table-tbody > tr > td {
    border-right: 0;
    border-right: 1px solid $c-gray;
    border-bottom: 1px solid $c-gray;
  }
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  border-bottom: 0;
  border-right: 1px solid $c-gray;
  padding: 12px;
  font-size: 13px;
}

.ant-table-thead > tr > th {
  border-right-color: lighten($c-gray, 5);
}

.ant-table-bordered .ant-table-thead > tr > th:last-child,
.ant-table-bordered .ant-table-tbody > tr > td:last-child {
  border-right: 0 !important;
}

//.ant-table-tbody>tr:hover:not(.ant-table-expanded-row):nth-child(2n)>td {
//    background-color: #f0f1f4;
//}
//
//.ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
//    background-color: #fcfcfd;
//}

.ant-table-thead > tr > th .ant-table-filter-icon,
.ant-table-thead > tr > th .anticon-filter {
  color: rgb(187, 187, 187);
}

@media only screen and (max-width: 1500px) {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    font-size: 12px;
    padding: 10px 6px !important;
  }
  i.controls::before {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1250px) {
  .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
    padding-right: 22px !important;
  }

  .ant-table-thead > tr > th {
    font-size: 10px !important;
  }
}

tr.ant-table-expanded-row td > .ant-table-wrapper {
  margin: 0;
}

.table-actions {
  a {
    &:hover {
      opacity: 0.5;
    }
  }
}

.ant-table-filter-dropdown-link {
  &:hover {
    cursor: pointer;
  }
}

.ant-table-column-title {
  color: $table-title-color;
  flex: unset;
} // color za sve naslove kolona

.ant-table-container {
  overflow-y: auto;
  scrollbar-width: none;
}
.ant-table-container::-webkit-scrollbar {
  display: none;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 2px;
  color: $k100;
} //color za poslednji naslov

#upload-box-avatar-0 {
  border: none !important;
  .anticon .anticon-edit svg {
    color: $wht;
  }
}

.ant-table {
  margin-top: -15px;
  overflow: hidden;
  // border-top: 1px solid #8D989F;
  border-top: none;
}

.ant-table-wrapper {
  .ant-spin-container {
    overflow: unset !important;
  }
}

.ant-input-prefix {
  margin-right: 12px;
}

.ant-table-pagination-right {
  justify-content: center;
}

// .ant-table-cell {
//   //padding-left: 1.5% !important;
// }
