$bgColor: #fff;
$bodyColor: #f0f2f5;
$wht: #fff; //KORISTI SE

$k2: #fafafa;
$k3: #f7f7f7;
$k5: #f2f2f2;
$k10: #e6e6e6;
$k15: #d9d9d9;
$k20: #ccc;
$k25: #bfbfbf;
$k30: #b3b3b3;
$k40: #999;
$k50: #808080;
$k60: #666;
$k70: #4d4d4d;
$k80: #333;
$k90: #191919;
$k100: #000; //KORISTI SE

//Redesign variables
//colors
$primary-color: #e30613;
$background-color: lighten(#f0f0f0, 2%);
$black-color: #000;
$c-gray: #f1f1f1;
$c-gray-lighter: #6c6c6c;
$table-text-color: #505050;
$table-title-color: $k100;

//text properties
$regular-font-size: 14px;
$large-font-size: 16px;
$heading-font-size: 30px;

$regular-line-height: 19px;
$large-line-height: 22px;
$heading-line-height: 41px;

$regular-font-weight: 400;
$btn-font-weight: 600;
$bold-font-weight: 700;

//table properties
$table-inner-border: 0.4px solid #c5c5c5;
//$table-border-radius:0 0 10px 10px;
$table-box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px; /*rgba(0, 0, 0, 0.2) 0px 4px 12px;*/
$table-icon-border: 1px solid #6c6c6c;
$table-icon-fill: #6c6c6c;
$table-icon-width: 15px;

$table-border-radius: 10px;
$table-border-radius-bottom: 0 0 10px 10px;
$table-border-radius-top: 10px 10px 0 0;

//button properties
$btn-text-align: center;
$btn-border: 1px solid;
$border-image-source: #e30613;
$btn-border-radius: 4px;
$btn-ternary-color: #606060;

//image properties
$round-img-width: 80px;
$round-img-height: 80px;
$round-img-border-radius: 50%;

//input properties
$input-border-radius: 6px;
$input-padding: 0px 16px;
$input-placeholder-color: #a1a1a1;

//button small
$small-btn-font-size: 11px;
$small-btn-width: 70px;
$small-btn-height: 30px;

////////////////////////////////////////

$base: #0071bc;
$txtColor: #696c74;
// $c-primary:#259dab;
//$c-primary:#4F66A0;
$c-primary: #216c97;
// $c-secondary:#464f63;
//$c-secondary:#DBE2EA;
$c-secondary: $wht;
$c-tertiary: #0092dd;

// $light:#f5f5f5;
$line: #ddd;
// $line2: #bdbdbd;
$darkBlue: #2e3192;
$hl: #ffcc00;
// $hl2: #e6e7ee;
// $blue: #0600e0;

$highlight: #5bbfde;
$danger: #d9534f;

$success: #a1ca51;
$error: #ce0000;

$purple: #444573;
$red: $error;
$yellow: #d5c93f;
$orange: orange;
$green: $success;
$gray: #5d737e;

// $font-stack: "Lato", sans-serif;
$font-stack: 'Roboto', sans-serif;
