.ant-pagination {
    &-item-active {
        border-color: $primary-color;
    
        &:hover {
            border-color: darken($primary-color, 5);
    
            a, a:visited {
                color: darken($primary-color, 5);
            }
        }
    
        a, a:visited {
            color: $primary-color;
    
            &:hover, &:active {
                text-decoration: none;
            }
        }
    }

    // &-options-size-changer {
    //     &.ant-select {
    //         margin-right: 0;
    //     }
    // }

    li {
        vertical-align: bottom;
    }

    .anticon {
        vertical-align: middle;
    }
    .ant-pagination-item:focus-visible a, .ant-pagination-item:hover a {
        color: darken($primary-color, 5);
    }
    .ant-pagination-prev:focus-visible .ant-pagination-item-link, .ant-pagination-next:focus-visible .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
        color: darken($primary-color, 5);
    }
}