.ant-drawer-content-wrapper {
  @media screen and (max-width: 320px) {
    width: 280px!important;
  }
}

.event-report {
  background: #fff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  padding: 20px;
  &__actions-block {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  }
  &__data {
    border-bottom: 1px solid #696c74;
    margin-bottom: 20px;
    &-row {
      word-break: break-all;
    }
  }
  &__consent-positive {
    display: inline-block;
    background: #77b64d;
    color: #fff;
    font-size: 12px;
    border-radius: 4px;
    padding: 5px 10px;
  }
  &__consent-negative {
    display: inline-block;
    background: #b64d4d;
    color: #fff;
    font-size: 12px;
    border-radius: 4px;
    padding: 5px 10px;
  }

  &__address-group {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    @media screen and (max-width: 768px) {
      gap: 0;
      align-items: flex-start;
      flex-direction: column;
    }
  }

  //Event Report Form
  &__form-drawer {
    @media screen and (min-width: 769px) {
      .ant-drawer-content-wrapper {
        width: 50%!important;
        max-width: 800px;
      }
    }
  }
  &__form-group {
    margin-bottom: 20px;
    border-bottom: 1px solid #696c74;
    @media screen and (min-width: 769px) {
      &-basic-info {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
      }
    }
    &-interests {
      padding-bottom: 20px;
    }
  }
  &__inline-group {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
    @media screen and (min-width: 769px) {
      flex-direction: row;
    }
  }
  &-main-interest-title {
    padding-bottom: 10px;
    border-bottom: 1px solid #696c74;
  }
  &-main-interest-group {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media screen and (max-width: 320px) {
      align-items: flex-start;
      flex-direction: column;
    }
    &-multibloc {
      border-bottom: 1px solid #696c74;
      margin-bottom: 24px;
      display: flex;
      gap: 10px;
    }
  }
  &-consent-checkbox {
    margin-top: 10px;
  }
}

.choose-catalogue-title {
  margin-top: 10px;
}
.choose-catalogue-item {
  margin: 0;
}

.interests {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(32%, 1fr));
  grid-row-end: span;
  row-gap: 10px;
  column-gap: 10px;
  @media screen and (max-width: 1376px) {
    grid-template-columns: repeat(auto-fit, minmax(49%, 1fr));
  }
}
.interest {
  border: 1px solid #d9d9d9;
  padding: 10px;
  h2 {
    font-weight: 700;
  }
  &__checkbox-group {
    display: flex;
    gap: 10px;
    &-multibloc {
      border-bottom: 1px solid #d9d9d9;
      margin-bottom: 10px;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
  }
  .catalogue-group {
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 12px;
    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }
  }
  .catalogue-print,
  .catalogue-pdf {
    display: flex;
    justify-content: space-between;
    .ant-form-item {
      margin-bottom: 6px;
      &:last-child {
        margin-bottom: 12px;
      }
    }
  }
}